<template>
  <div class="info_wrapper">
    <van-form
        ref="form"
        :show-error-message="false"
    >
      <div class="info_header" ref="title">
        <transition name="van-slide-left">
          <div v-show="!isCheckPolicyNo" class="header_msg">身份认证</div>
        </transition>
        <transition name="van-slide-left">
          <div v-show="isCheckPolicyNo" class="header_msg">预约联系人</div>
        </transition>
      </div>
      <div class="main_info">
        <transition name="van-slide-left">
          <van-field
              v-show="isCheckPolicyNo"
              :disabled="disabled.value"
              class="default_field"
              v-model="info.mobile"
              left-icon="phone-o"
              :maxlength="11"
              name="mobile"
              input-align="right"
              label="联系方式"
              placeholder="请输入您的联系方式"
              label-class="default_field_label"
              :rules="[{ required:true,trigger:'onBlur', message: '联系方式不为空' }]"
              required
          />
        </transition>
        <transition name="van-slide-left">
          <van-field
              v-show="isCheckPolicyNo"
              :disabled="disabled.value"
              class="default_field"
              v-model="info.name"
              left-icon="user-o"
              name="name"
              input-align="right"
              label="姓名"
              placeholder="请输入您的姓名"
              label-class="default_field_label"
              :rules="[{ required:true,trigger:'onBlur', message: '姓名不为空' }]"
              required
          />
        </transition>
        <transition name="van-slide-left">
          <van-field
              v-show="!isCheckPolicyNo"
              class="default_field"
              :disabled="disabled.value"
              v-model="info.policyNo"
              left-icon="balance-list-o"
              name="policyNo"
              input-align="right"
              label="保单号"
              placeholder="请输入您的保单号"
              label-class="default_field_label"
              :rules="[{ required:true, message: '保单号不为空' }]"
              required
          />
        </transition>
        <div class="notice_field">
          <van-icon color="#d3ab55"
                    name="bulb-o" size="0.35rem"/>
          <span class="notice_text">
            {{ this.isCheckPolicyNo ? '工作人员将通过改手机号与您联系并确认具体预约消息，请确保该号码可正常通话。' : '保单号信息需用于查询相关服务资料，请填写正确的保单号。' }}
          </span>
        </div>
      </div>
      <div class="form_bottom_box">
        <div class="bottom_handle">
          <van-button class="handle_btn" round type="info" :loading="loading" :disabled="disabled.value"
                      @click="handleConfirm"
                      :text="disabled.value?disabled.buttonText:'确认'"/>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Button, Field, Form, Icon, Toast} from "vant";
import {bookNursing, bookStatusCheck, verifyChannelByCode} from "@/api/book";
import {mapActions, mapState} from "vuex";

export default {
  name: 'BookNursingForm',
  data() {
    return {
      loading: false,
      isCheckPolicyNo: false,
      info: {
        policyNo: '',//保单号
        mobile: '',//手机号
        name: '',//姓名
      },
      disabled: {
        value: true,
        buttonText: "数据有误",
      },
      reserveType: 'H5',
    };
  },
  computed: {
    ...mapState([
      'bookNursingFormPolicyNo',
      'bookNursingFormChannelCode',
    ])
  },
  components: {
    'van-form': Form,
    'van-field': Field,
    'van-button': Button,
    'van-icon': Icon,
  },
  mounted() {
    const {channelCode} = this.$route.query;
    this.loading = true;
    if (channelCode) {
      verifyChannelByCode({channelCode}).then(res => {
        console.log(res.data);
        if (res.data) {
          this.setBookNursingFormChannelCode(channelCode)
          this.enable();
        } else {
          this.disable('渠道代码有误')
        }
      })
    } else {
      this.disable('数据有误');
      return
    }
  },
  created() {
  },
  methods: {
    ...mapActions([
      'setBookNursingFormPolicyNo',
      'setBookNursingFormChannelCode',
    ]),
    enable() {
      this.info.policyNo = this.bookNursingFormPolicyNo;
      this.disabled = {
        value: false,
      }
      setTimeout(() => {
        this.loading = false;
      }, 600)
    },
    disable(msg='数据有误') {
      this.disabled = {
        value: true,
        buttonText: msg,
      }
      setTimeout(() => {
        this.loading = false;
      }, 600)
    },
    handleConfirm() {
      const form = this.$refs['form'];
      if (this.isCheckPolicyNo) {
        form.validate().then(() => {
          bookNursing({
            policyNo: this.info.policyNo,
            reserveUserMobile: this.info.mobile,
            reserveUserName: this.info.name,
            reserveType: this.reserveType
          }).then(res => {
            if (res.code == 200) {
              this.$router.push({
                name: 'result',
                path: 'result',
                params: {
                  resultType: 2,
                  result: res.data
                }
              });
            } else {
              Toast(res.message)
            }
          })
        }).catch((err) => {
          Toast(err[0].message);
        })
      } else {
        form.validate('policyNo').then(() => {
          bookStatusCheck({
            policyNo: this.info.policyNo
          }).then(res => {
            this.setBookNursingFormPolicyNo(this.info.policyNo);
            const code = res.code;
            if (code === 10020) {
              this.$router.push({
                name: 'result',
                path: 'result',
                params: {
                  resultType: 0,
                }
              });
            } else if (code === 10021) {
              this.$router.push({
                name: 'result',
                path: 'result',
                params: {
                  resultType: 1,
                  result: res.data,
                }
              });
            } else if (code === 200) {
              form.resetValidation()
              this.isCheckPolicyNo = !this.isCheckPolicyNo;
            } else {
              Toast(res.message);
            }
          }).catch(err => {
            console.error(err)
          })
        }).catch((res) => {
          Toast(res.message);
        })
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "index";
</style>