import {baseConfig, request} from "../utils/request";

/**
 * 预约保单号检测
 * @param params
 * @returns {Promise<unknown>}
 */
const bookStatusCheck = (params = {}) => {
    return request({
        url: `${baseConfig.baseBigTreeUrl}/order/white-list/reserve-status`,
        method: 'GET',
        params
    })
}
const bookNursing = (data = {}) => {
    return request({
        url: `${baseConfig.baseBigTreeUrl}/order/white-list/reserve`,
        method: 'POST',
        data
    })
}
/**
 * verifyChannelByCode
 * @param params
 * @returns {Promise<unknown>}
 */
const verifyChannelByCode = (params = {}) => {
    return request({
        url: `${baseConfig.baseBigTreeUrl}/channel/feign/verify/channel/byCode`,
        method: 'GET',
        params
    })
}
export {
    bookStatusCheck,
    bookNursing,
    verifyChannelByCode,
}